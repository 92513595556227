var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Informações básicas" },
                  },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Título "),
                                  ]),
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid": _vm.$v.item.title.$error,
                                    },
                                    model: {
                                      value: _vm.item.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "title", $$v)
                                      },
                                      expression: "item.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mt-1" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(" Descrição "),
                                  ]),
                                  _c("editor", {
                                    staticClass: "description-editor-1",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 600,
                                        selector: "description-editor-1",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "description", $$v)
                                      },
                                      expression: "item.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mt-1 mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(" Sobre o produto - "),
                                    _c("small", [
                                      _vm._v(
                                        "Será exibido no final da página."
                                      ),
                                    ]),
                                  ]),
                                  _c("editor", {
                                    staticClass: "description-editor-2",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 300,
                                        selector: "description-editor-2",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.about,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "about", $$v)
                                      },
                                      expression: "item.about",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Sites de buscas" } },
                  [
                    _c("b-card-text", [
                      _c("p", { staticClass: "mb-3" }, [
                        _vm._v(
                          ' Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo. '
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Título SEO"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.title_seo,
                                  expression: "item.title_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.title_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "title_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("URL"),
                              ]),
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "input-group-merge",
                                  attrs: { prepend: "/questoes/" },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "text-primary pl-1",
                                    model: {
                                      value: _vm.item.slug,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "slug", $$v)
                                      },
                                      expression: "item.slug",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group mb-0" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Descrição para SEO"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.description_seo,
                                  expression: "item.description_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { maxlength: "320" },
                              domProps: { value: _vm.item.description_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "description_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Imagem de destaque" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("input", {
                                  staticClass: "form-control-file hidden",
                                  attrs: {
                                    id: "image",
                                    type: "file",
                                    accept: "image/*",
                                  },
                                  on: { change: _vm.previewImage },
                                }),
                                _c("b-form-group", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "d-block text-center",
                                      staticStyle: {
                                        border: "1px solid rgb(222 222 222)",
                                        "border-radius": "4px",
                                        "border-style": "dashed",
                                        cursor: "pointer",
                                        "font-size": "1.1rem",
                                      },
                                      attrs: { for: "image" },
                                    },
                                    [
                                      !_vm.preview
                                        ? _c(
                                            "div",
                                            { staticClass: "pt-5 py-4" },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  size: "19",
                                                  icon: "ImageIcon",
                                                },
                                              }),
                                              _vm._v(" Selecionar imagem "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.preview
                                        ? [
                                            _c("b-img", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                for: "image",
                                                src: _vm.preview,
                                                fluid: "",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", [
                                      _vm._v("Vídeo de apresentação"),
                                    ]),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: "Link do YouTube/Vimeo",
                                      },
                                      model: {
                                        value: _vm.item.video_url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "video_url", $$v)
                                        },
                                        expression: "item.video_url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("label", [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Label da imagem "),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.label_image.$error,
                                      },
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.item.label_image,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "label_image", $$v)
                                        },
                                        expression: "item.label_image",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: {
                                      "margin-top": "4px",
                                      "font-size": "0.9rem",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Será exibido sobre a imagem de destaque. "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "API Questões" } },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Área "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.api_questoes_area,
                                    expression: "item.api_questoes_area",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.item.api_questoes_area.$error,
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.api_questoes_area },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "api_questoes_area",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 col-12" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Edital "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.api_questoes_edital,
                                    expression: "item.api_questoes_edital",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.item.api_questoes_edital.$error,
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.item.api_questoes_edital,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "api_questoes_edital",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Detalhes" } },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tempo de acesso "),
                                _c("small", [_vm._v("(dias) ")]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####"],
                                    expression: "['####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.validity,
                                    expression: "item.validity",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.item.validity.$error,
                                },
                                attrs: {
                                  type: "tel",
                                  min: "1",
                                  placeholder: "365",
                                },
                                domProps: { value: _vm.item.validity },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "validity",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Sales force "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectSalesForce",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.salesforce.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsSalesForce,
                                    },
                                    on: { search: _vm.fetchSalesForce },
                                    model: {
                                      value: _vm.item.salesforce,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "salesforce", $$v)
                                      },
                                      expression: "item.salesforce",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectSalesForce.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Preço" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Preço "),
                                    ]),
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.$v.item.price.$error,
                                          },
                                          attrs: { placeholder: "0,00" },
                                          model: {
                                            value: _vm.item.price,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "price", $$v)
                                            },
                                            expression: "item.price",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(" Promocional "),
                                    ]),
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "form-control",
                                          attrs: { placeholder: "0,00" },
                                          model: {
                                            value: _vm.item.sale,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "sale", $$v)
                                            },
                                            expression: "item.sale",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(" Promoção válida até "),
                                    ]),
                                    _c("flat-pickr", {
                                      staticClass: "form-control",
                                      attrs: {
                                        config: {
                                          dateFormat: "d/m/Y",
                                          altFormat: "Y-m-d",
                                          locale: "pt",
                                        },
                                      },
                                      model: {
                                        value: _vm.item.promotion_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "promotion_date",
                                            $$v
                                          )
                                        },
                                        expression: "item.promotion_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Opcionais" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-row", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-12 mb-1" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Depoimento(s) "),
                                  _c("small", [_vm._v("(Aprovados)")]),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectApproved",
                                    attrs: {
                                      multiple: "",
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsApproveds,
                                    },
                                    on: { search: _vm.fetchApproveds },
                                    model: {
                                      value: _vm.item.approveds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "approveds", $$v)
                                      },
                                      expression: "item.approveds",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectApproved.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_store_discount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_store_discount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.disable_store_discount",
                                      },
                                    },
                                    [_vm._v(" Não aplicar desconto em loja ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_billet,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_billet",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_billet",
                                      },
                                    },
                                    [_vm._v(" Desabilitar venda no boleto ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_popup,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_popup",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_popup",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar Pop-up de interessado "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_form_contact,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_form_contact",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_form_contact",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar formulário de contato "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_whatsapp,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_whatsapp",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_whatsapp",
                                      },
                                    },
                                    [_vm._v(" Desativar botão do WhatsApp ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Tags" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-tags", {
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.$v.item.tags.$error,
                                      },
                                      attrs: {
                                        separator: ",;",
                                        "no-add-on-enter": "",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.item.tags,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "tags", $$v)
                                        },
                                        expression: "item.tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mb-0 mt-1" }, [
                          _vm._v("Adicione tags separando-as por vírgula."),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Status" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-0" },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid": _vm.$v.item.status.$error,
                              },
                              attrs: {
                                label: "title",
                                options: [
                                  { title: "Ativo", code: 1 },
                                  { title: "Inativo", code: 0 },
                                ],
                              },
                              model: {
                                value: _vm.item.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "status", $$v)
                                },
                                expression: "item.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "products-questions-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Cadastrar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }